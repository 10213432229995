import * as React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HTML5Video from "../global/video.js";
import BackToTop from "../global/BackToTop.js";


class Content extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      VideoSrc: undefined,
      VideoThumb: undefined,
    };
  }
  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClick = (video) => {
    let VideoSrc;
    let VideoThumb;
    let VideoTrackLabel;
    switch (video) {
      case "Arianna":
        VideoSrc =
          "https://player.vimeo.com/progressive_redirect/playback/917245801/rendition/1080p/file.mp4?loc=external&signature=0c60be847aaa3206cab1ae8b4012ce6c9ae6011b8452f6429ab4ff377111a92c";
        VideoTrackLabel = "Arianna's Center";
        break;
      case "Cleve":
        VideoSrc =
          "https://player.vimeo.com/progressive_redirect/playback/917246300/rendition/1080p/file.mp4?loc=external&signature=6685ec3604a3d0f4cc3a1c50c1eea38d6076e13692c1a0463c9f4f6e579ac315";
        VideoTrackLabel = "Memorial Quilt";
        break;
      case "June":
        VideoSrc =
          "https://player.vimeo.com/progressive_redirect/playback/917245901/rendition/1080p/file.mp4?loc=external&signature=72bad9a467616e09df2290b51c0a69def2cf5b946f772fa04f4ff9707bb401de";
        VideoTrackLabel = "My Brother's Keeper";
        break;
      case "Kirk":
        VideoSrc =
          "https://player.vimeo.com/progressive_redirect/playback/917245731/rendition/1080p/file.mp4?loc=external&signature=3aaf443c24ec89c91e1a120a3546b6919d8f4fddec3bff9c195fa25e58a52a86";
        VideoTrackLabel = "Abounding Prosperity";
        break;
      case "Lance":
        VideoSrc =
          "https://player.vimeo.com/progressive_redirect/playback/917246122/rendition/1080p/file.mp4?loc=external&signature=ada016813bc782cbb487ebb90c1bf174e3a3160b85b622767e5ccccdd2dc4ea9";
        VideoTrackLabel = "Lance Video";
        break;
      case "Joe":
        VideoSrc =
          "https://player.vimeo.com/progressive_redirect/playback/917245995/rendition/1080p/file.mp4?loc=external&signature=a760e18f3b9e728ecdf043367c2603d5534b0b821295e367c209edf3521d5eb1";
        VideoTrackLabel = "Joe Video";
        break;
    }
    this.setState({
      show: true,
      //VideoThumb: VideoThumb,
      VideoSrc: VideoSrc,
      VideoTrackLabel: VideoTrackLabel,
    });
    //this.handleShow();
  };

  render() {
    return (
      <div className={`${this.props.show ? "d-block" : "d-none"}`}>
        <Container
          fluid
          className="hero community-commitments"
          role="img"
          aria-label="Group of people gathered together in a series of images"
        />

        <Container className="community-commitments">
          <Row>
            <Col xs={9} lg={10} className="m-auto px-0">
              <div className="hero-callout muted-yellow-dark">
                
                <h1 className="title">
                  <span className="sub-title d-block">Community Commitments</span>
                  Together we <br class="d-block d-sm-none" />
                  can help end <br class="d-block d-sm-none" />
                  the HIV epidemic
                </h1>
              </div>
              <p>
              We know that it takes a combination of science and social change to help end the HIV epidemic for everyone, everywhere. It's only by supporting and working with advocates and organizations across the country that we can reach this goal. Together, we're working tirelessly to improve health equity and bring awareness to the HIV epidemic. <strong>These organizations are recipients of Gilead grant funding.</strong>
              </p>
              <Container>
                <Row className="videos">
                  <HTML5Video
                    handleClose={this.handleClose}
                    show={this.state.show}
                    thumbnail={this.state.VideoThumb}
                    url={this.state.VideoSrc}
                    videoTrackLabel={this.state.VideoTrackLabel}
                  />
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img
                      onClick={() => this.handleClick("Arianna")}
                      src={"/Ariannas-center_1.png"}
                      alt="Arianna’s Center, an advocacy organization deeply rooted in HIV care in the latinx transgender community."
                    />
                    <h3 className="title">
                      Arianna’s Center & Gilead: Helping Address Unmet Needs of
                      the Transgender Community in HIV Care
                    </h3>
                    <p className="description">
                      We are proud to support Arianna’s Center, an
                      advocacy organization deeply rooted in the Hispanic/Latino
                      transgender community that is committed to providing more
                      equitable access to necessary HIV resources and supporting
                      the unique needs of trans people in South Florida and
                      Puerto Rico.
                    </p>
                  </Col>
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img
                      onClick={() => this.handleClick("Cleve")}
                      src={"/AIDS-memorial-quilt_2.png"}
                      alt="AIDS Memorial Quilt honoring the largest living memorial project in the world."
                    />
                    <h3 className="title">
                      AIDS Memorial Quilt & Gilead: Honoring the Largest Living
                      Memorial Project in the World
                    </h3>
                    <p className="description">
                      The AIDS Memorial Quilt honors the history of HIV/AIDS in
                      the US and the lives of all those we lost to AIDS. The
                      quilt features up to 50,000 panels stitched together by
                      friends and families to honor their loved ones and inspire
                      the next generation in their fight against HIV today.
                      We are proud to support the AIDS Memorial Quilt as a
                      powerful tool for remembrance, healing, education,
                      and&nbsp;action.
                    </p>
                  </Col>
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img
                      onClick={() => this.handleClick("June")}
                      src={"/My-brothers-keeper_3.png"}
                      alt="Non-profit organization, My Brother’s Keeper, helps advance HIV care in impacted communities in Mississippi."
                    />
                    <h3 className="title">
                      My Brother’s Keeper & Gilead: Helping Advance Consistent,
                      Quality HIV Care
                    </h3>
                    <p className="description">
                      We are proud to support nonprofit organization My Brother’s Keeper
                      to help advance HIV prevention strategies and reduce
                      health disparities for disproportionately impacted
                      communities in&nbsp;Mississippi.
                    </p>
                  </Col>
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img
                      onClick={() => this.handleClick("Kirk")}
                      src={"/Abounding-prosperity_4.png"}
                      alt="Abounding Prosperity organization provides HIV services in communities in Dallas, Texas."
                    />
                    <h3 className="title">
                      Abounding Prosperity & Gilead: Helping Transform HIV
                      Services for Communities in Texas
                    </h3>
                    <p className="description">
                      We are proud to support Abounding Prosperity and their
                      work to address existing health, social, and economic
                      disparities in HIV among Black gay and bisexual men,
                      cisgender and transgender women, and their families.
                    </p>
                    <p className="description">
                      Gilead honors the life of Kirk Myers-Hill. He was a
                      giant in the community who worked tirelessly to address
                      social and health disparities and help end the HIV
                      epidemic. His commitment to improving the quality of life
                      and amplifying the rights of Black people and the LGBTQ+
                      community in Dallas, Texas, will benefit generations to
                      come. We are grateful for the partnerships he created and
                      the work he accomplished.
                    </p>
                  </Col>

                  <Col xs={12} lg={6} className="video-card px-0">
                    <img
                      onClick={() => this.handleClick("Lance")}
                      src={"/SF-Community-Health-Center_5.png"}
                      alt="San Francisco Community Health Center is working to improve access to critical services for vulnerable communities."
                    />
                    <h3 className="title">
                      San Francisco Community Health Center & Gilead: Improving
                      Access to Critical HIV Services
                    </h3>
                    <p className="description">
                      The San Francisco Community Health Center is working to
                      improve access to critical services for vulnerable
                      communities disproportionately impacted by HIV. We are
                      proud to support these efforts as part of our shared goal
                      to help end the HIV epidemic for everyone, everywhere.
                    </p>
                  </Col>
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img
                      onClick={() => this.handleClick("Joe")}
                      src={"/Oakland-LGBTQ_6.png"}
                      alt="Oakland LGBTQ Community Center works to improve sexual health education and health services."
                    />
                    <h3 className="title">
                      Oakland LGBTQ Community Center & Gilead: Improving Sexual
                      Health Education and Health Services Offered in Oakland,
                      CA
                    </h3>
                    <p className="description">
                      We are proud to support the Oakland LGBTQ Community Center to help
                      enhance and sustain the well-being of individuals impacted
                      by HIV through improving sexual health education and
                      health services.
                    </p>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <BackToTop />
      </div>
    );
  }
}

export default Content;
