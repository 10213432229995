import * as React from 'react';
import { Component } from 'react';
import Layout from '../../components/global/layout.js';
import Content from '../../components/community-commitments/content.js';
import '@fortawesome/fontawesome-free/css/all.css';
import '../../sass/app.scss';

class Partners extends Component {
    render() {
        return (
            <>
                <Layout section='community-involvement' page='community-commitments'>
                    <Content section='community-involvement' />
                </Layout>
            </>
        );
    }
}

export default Partners;
