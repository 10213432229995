import * as React from "react";
import { Component } from "react";
import { Col } from "react-bootstrap";
import ArrowUp from "../../images/back-to-top.svg";

class BackToTop extends Component {
  constructor(props) {
    super();
  }
  onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <>
        <div
            onClick={this.onClick}
            className="back-to-top w-100"
            /* data-analytics-event-category="Buttons"
            data-analytics-event-label="HIV Testing: Back To Top" */
          >
            <img className="d-block m-auto" src={ArrowUp} />
  
          </div>
      </>
    );
  }
}

export default BackToTop;
